export const faultTypes = {
  all: 'All',
  electrical: 'Electrical',
  mechanical: 'Mechanical',
  other: 'Other',
  scheduledLoss: 'ScheduledLoss',
  unassigned: 'Unassigned',
}

export const faultTags = {
  all: 'All',
  electrical: 'Electrical',
  mechanical: 'Mechanical',
  other: 'Other',
  scheduledLoss: 'ScheduledLoss',
  unassigned: 'Unassigned',
}

export const faultTagsStr = {
  all: 'All',
  electrical: 'Electrical',
  mechanical: 'Mechanical',
  other: 'Other',
  scheduledLoss: 'Scheduled Loss',
  unassigned: 'Unassigned',
}

export const faulTagItems = {
  electrical: 'Electrical',
  mechanical: 'Mechanical',
  sl: 'SL',
  operator: 'Operator',
  ulf: 'ULF',
  ulo: 'ULO',
  other: 'Other',
  unassigned: 'Unassigned',
}