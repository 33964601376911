import { atom, createStore } from 'jotai'
import { User } from '../models/user'
import { DateRange } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { faultTags, faultTypes } from './constants/fault-tags'
import { SetCurrentTime, SetStartOfDayTime } from '../utils/time-set.helper'
import { MutableRefObject } from 'react'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'

export const store = createStore()
export const currentUserAtom = atom<User>({
  email: '',
  id: '',
  name: '',
  roles: [],
})

export const commentAtom = atom<boolean>(false)

dayjs.extend(utc)
let currentDate = dayjs()

export const todaysDate = atom<dayjs.Dayjs>(currentDate)
export const globalDates = atom<DateRange<Dayjs>>([
  SetStartOfDayTime(currentDate.subtract(6, 'day')),
  SetCurrentTime(currentDate),
])

export const globalFaultType = atom<string>(faultTypes.all)
export const globalFaultTag = atom<string>(faultTags.all)
export const globalDowntimeReason = atom<string>('')
export const globalTags = atom<string>('')
export const globalUpdateDowntimeId = atom<string>('')

export const globalDeleteSuccessState = atom<boolean>(false)
export const globalDeleteErrorState = atom<boolean>(false)
export const globalPostSuccessState = atom<boolean>(false)
export const globalPostErrorState = atom<boolean>(false)

export const globalGridApiRef = atom<MutableRefObject<GridApiPro> | null>(null)

export const globalModalState = atom<boolean>(false)
